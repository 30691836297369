
import { defineComponent } from 'vue'
// import { useRouter } from 'vue-router'
// // import * as process from 'process'
//
// const { currentRoute } = useRouter()
// const route = currentRoute.value
// onMounted(() => {
//   const code = route.query.code
//   console.log('code', code)
// })

export default defineComponent({
  name: 'App',
  data () {
    return {
      language: 'en',
      activeIndex: '',
      navs: [
        {
          parentNav: { index: '/2024', name: 'Home' }
        },
        {
          parentNav: { index: 'Related Information', name: 'Related Information' },
          children: [
            // { navName: "index", url: "/" },
            { index: '/2024/venue', name: 'Venue' },
            {
              index: '/2024/creps',
              name: 'CREPS'
            },
            { index: '/2024/strasbourg', name: 'The city of Strasbourg' },
            {
              index: '/2024/conferencehistory',
              name: 'Conference History'
            }
          ]
        },
        {
          parentNav: { index: 'travelRegistration', name: 'Travel and Application' },
          children: [
            {
              index: '/2024/travelstrasbourg',
              name: 'Getting Strasbourg'
            },
            {
              index: '/2024/hotel',
              name: 'Hotel'
            },
            // {
            //   index: '/2024/registration',
            //   name: 'Registration'
            // },
            {
              index: '/2024/application',
              name: 'Application'
            }
          ]
        },
        {
          parentNav: {
            index: '/2024/speaker',
            name: 'Speaker'
          }
        },
        {
          parentNav: {
            index: '/2024/programNav',
            name: 'News and Program'
          },
          children: [
            {
              index: '/2024/news',
              name: 'Workshop News'
            },
            {
              index: '/2024/program',
              name: 'Program'
            },
            {
              index: '/2024/committees',
              name: 'Committees'
            }
          ]
        },
        {
          parentNav: {
            index: '/2024/contact',
            name: 'Contact Us'
          }
        }
      ],
      supporterImgs: [
        // { name: 'conference', url: require('@/assets/iacss_logo_klein.gif'), hrefUrl: 'https://iacss.org/' },
        // { name: 'zju', url: require('@/assets/logo_zju.png'), hrefUrl: 'https://www.zju.edu.cn/english/' },
        // { name: 'College of Eduction, Zhejiang University', hrefUrl: 'http://www.ced.zju.edu.cn/' },
        // { name: 'Zhejiang University Education Foundation Shen Shanhong Fund' },
        // { name: 'The Fundamental Research Funds for the Central Universities' },
        // { name: 'Digital Sport and Health Laboratory, Zhejiang University' }
      ],
      sponsors: [
        // { name: 'dartfish', url: require('@/assets/sponsors/dartfish.png'), hrefUrl: 'https://www.dartfish.com/' },
        // { name: 'wishare', url: require('@/assets/sponsors/wishare.png'), hrefUrl: 'http://www.xports.cn/' },
        // { name: 'dongsport', url: require('@/assets/sponsors/dongsport.png'), hrefUrl: 'http://www.dongsport.com/' },
        // { name: 'info', url: require('@/assets/sponsors/info.png'), hrefUrl: 'https://www.infoinstruments.cn/' }
      ]
    }
  },
  watch: {
    $route (to) {
      this.updateNav(to.path)
    }
  },
  methods: {
    navHandleSelect (key: string) {
      sessionStorage.setItem('currentPath', key)
      this.$router.push(key)
    },
    toCn () {
      this.language = 'cn'
      this.$router.push('cn')
    },
    toEn () {
      this.language = 'en'
      this.$router.push('/')
    },
    updateNav (path:string) {
      console.log(path)
      // 判断是是英文还是中文网站
      const pathArray = path.split('/')
      // 中文
      if (pathArray.indexOf('cn') >= 0) {
        this.language = 'cn'
        this.activeIndex = path
        // eslint-disable-next-line brace-style
      }
      // 英文
      else {
        this.language = 'en'
        if (pathArray[1] === '') { this.activeIndex = '2024' } else { this.activeIndex = path }
      }
    }
  }
})
