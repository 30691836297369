
import { defineComponent } from 'vue'
// import * as process from 'process'

export default defineComponent({
  name: 'App',
  data () {
    return {
      language: 'en',
      activeIndex: '',
      navs: [
        {
          parentNav: { index: '/2023', name: 'Home' }
        },
        {
          parentNav: { index: 'venueNav', name: 'Venue' },
          children: [
            // { navName: "index", url: "/" },
            { index: '/2023/venue', name: 'Venue' },
            {
              index: '/2023/venueHangzhou',
              name: 'The City of Hangzhou'
            },
            { index: '/2023/zju', name: 'Zhejiang University' },
            { index: '/2023/coe', name: 'College of Education' },
            { index: '/2023/dss', name: 'Department of Sports Science' },
            { index: '/2023/sportsteam', name: 'Sports Big Data Innovation Team' },
            {
              index: '/2023/conferencehistory',
              name: 'History'
            }
          ]
        },
        {
          parentNav: { index: '/2023/travelNav', name: 'Travel' },
          children: [
            {
              index: '/2023/travelhangzhou',
              name: 'Getting Hangzhou'
            },
            {
              index: '/2023/accommodation',
              name: 'Accommodation'
            }
          ]
        },
        {
          parentNav: {
            index: '/2023/submissionNav',
            name: 'Submission and Registration'
          },
          children: [
            {
              index: '/2023/datedeadline',
              name: 'Dates and Deadlines'
            },
            {
              index: '/2023/submission',
              name: 'Abstract Submission'
            },
            {
              index: '/2023/registration',
              name: 'Registration Information'
            },
            {
              index: '/2023/presentation',
              name: 'Presentation Information'
            },
            {
              index: '/2023/award',
              name: 'Award'
            }
          ]
        },
        {
          parentNav: {
            index: '/2023/programNav',
            name: 'Program'
          },
          children: [
            {
              index: '/2023/program',
              name: 'Program'
            },
            {
              index: '/2023/conferencenews',
              name: 'Conference News'
            },
            {
              index: '/2023/conferencethemes',
              name: 'Conference Themes'
            },
            {
              index: '/2023/committees',
              name: 'Committees'
            },
            {
              index: '/2023/keynote',
              name: 'Keynote Speaker'
            }
          ]
        },
        {
          parentNav: {
            index: '/2023/sponsoring',
            name: 'Sponsoring'
          }
        },
        {
          parentNav: {
            index: '/2023/contact',
            name: 'Contact Us'
          }
        }
      ],
      publicPath: process.env.BASE_URL,
      supporterImgs: [
        { name: 'conference', url: require('@/assets/iacss_logo_klein.gif'), hrefUrl: 'https://iacss.org/' },
        { name: 'zju', url: require('@/assets/logo_zju.png'), hrefUrl: 'https://www.zju.edu.cn/english/' },
        { name: 'College of Eduction, Zhejiang University', hrefUrl: 'http://www.ced.zju.edu.cn/' },
        { name: 'Zhejiang University Education Foundation Shen Shanhong Fund' },
        { name: 'The Fundamental Research Funds for the Central Universities' },
        { name: 'Digital Sport and Health Laboratory, Zhejiang University' }
      ],
      sponsors: [
        { name: 'dartfish', url: require('@/assets/sponsors/dartfish.png'), hrefUrl: 'https://www.dartfish.com/' },
        { name: 'wishare', url: require('@/assets/sponsors/wishare.png'), hrefUrl: 'http://www.xports.cn/' },
        { name: 'dongsport', url: require('@/assets/sponsors/dongsport.png'), hrefUrl: 'http://www.dongsport.com/' },
        { name: 'info', url: require('@/assets/sponsors/info.png'), hrefUrl: 'https://www.infoinstruments.cn/' }
      ]
    }
  },
  watch: {
    $route (to) {
      this.updateNav(to.path)
    }
  },
  methods: {
    navHandleSelect (key: string) {
      sessionStorage.setItem('currentPath', key)
      console.log(key)
      this.$router.push(key)
    },
    toCn () {
      this.language = 'cn'
      this.$router.push('cn')
    },
    toEn () {
      this.language = 'en'
      this.$router.push('/')
    },
    updateNav (path:string) {
      // 判断是是英文还是中文网站
      const pathArray = path.split('/')
      // 中文
      if (pathArray.indexOf('cn') >= 0) {
        this.language = 'cn'
        this.activeIndex = path
        // eslint-disable-next-line brace-style
      }
      // 英文
      else {
        this.language = 'en'
        this.activeIndex = path
        // if (pathArray[1] === '') { this.activeIndex = '' } else { this.activeIndex = pathArray[1] }
      }
    }
  }
})
